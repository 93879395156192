import { createWebHistory, createRouter } from 'vue-router'
import Home from '../views/Home.vue'
// import store from '../store'

const routes = [{
        path: '/',
        name: 'HomePage',
        component: Home
    },
    {
        path: '/admin',
        name: 'Login Admin',
        meta: { requiresAdmin: true },
        component: () =>
            import ( /* webpackChunkName: "admin" */ '../views/LoginAdmin.vue')
    },
    {
        path: '/quiz/:id',
        name: 'Quiz Page',
        meta: { requiresAuth: true },
        component: () =>
            import ( /* webpackChunkName: "quiz" */ '../views/Quiz.vue')
    },
    {
        path: '/quiz/:id/finish/:att_id/user/:user_id',
        name: 'Quiz Finish Page full',
        meta: { requiresAdmin: true },
        component: () =>
            import ( /* webpackChunkName: "quiz2" */ '../views/QuizFinish.vue')
    },
    {
        path: '/quiz/:id/finish/:att_id',
        name: 'Quiz Finish Page',
        meta: { requiresAuth: true },
        component: () =>
            import ( /* webpackChunkName: "quiz" */ '../views/QuizFinish.vue')
    },
    {
        path: '/dashboard',
        name: 'User Page',
        meta: { requiresAuth: true },
        component: () =>
            import ( /* webpackChunkName: "quiz" */ '../views/User.vue')
    },
    {
        path: '/leaderboard',
        name: 'User Rate Page',
        meta: { requiresAuth: true },
        component: () =>
            import ( /* webpackChunkName: "quiz" */ '../views/Leaderboard.vue')
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})


// router.beforeEach((to, from, next) => {
//     const isLoggedIn = router.app.$store.getters.isLoggedIn
//     const isAdmin = router.app.$store.getters.isAdmin
//     console.log(isLoggedIn,isAdmin);
//     if (to.meta.requiresAuth && !isLoggedIn) {
//         next('/')
//     } else if (to.meta.requiresAdmin && !isAdmin) {
//         next('/')
//     } else {
//         next()
//     }
// })

export default router