<template>
 <div style="display: contents">
    <nav class="navbar sticky-top bg-white  shadow">
      <div class="d-flex flex-sm-row flex-column justify-content-between container">
        <a class="text-decoration-none" href="/"><h3 class="navbar-text mb-0 text-body ">Всероссийская онлайн-олимпиада по географии</h3></a>

        <div v-if="isLoggedIn" class="d-flex justify-content-end col-12 col-sm-5">
          <a v-if="isAdmin" type="button" class="btn btn-link-primary py-3 px-3 py-sm-2 " title="Панель управления" href="/admin">
            <font-awesome-icon icon="fa-solid fa-gear" />
          </a>          
          <a v-if="isAdmin || allowresults" type="button" title="Результаты олимпиады" class="btn btn-link-primary py-3 px-3 py-sm-2 ms-3" href="/leaderboard">
            <font-awesome-icon icon="fa-solid fa-chalkboard-teacher" />
          </a>
          <a type="button" class="btn btn-link-primary py-3 px-3 py-sm-2 ms-3" title="Прохождение тестов" href="/dashboard">
            <font-awesome-icon icon="fa-solid fa-user" />
          </a>
          <button type="button" class="btn btn-link-primary py-3 py-sm-2 ms-3" title="Выход" @click="logout">
            <font-awesome-icon icon="fa-solid fa-sign-out-alt" class="me-2"/>
            {{ "Выход" }}
          </button>
        </div>
        <div v-else class="d-sm-flex justify-content-sm-end d-grid gap-2 col-12 col-sm-3 ">
          <button type="button" class="btn btn-link-primary py-3 py-sm-2" @click="openLoginWindow">
            <font-awesome-icon icon="fa-solid fa-right-to-bracket" class="me-2"/>
            {{ "Войти" }}
          </button>
        </div>
      </div>    
    </nav>
    
    <div class="login-block overflow-hidden bg-white position-fixed vh-100 vw-100 expand-block"
      v-if="showLoginWindow">
      <div class="d-flex justify-content-center align-items-center vh-100 vw-100">
          <div  class="card card-shadow login-box z-1030">
            <div class="card-header d-flex justify-content-between">
              <h3>Вход</h3>
              <div @click="closeLoginWindow" class="cursor-pointer px-3 py-2"><font-awesome-icon icon="fa-solid fa-times" class="text-muted2"/> </div>
            </div>
            <div class="card-body p-4">
              <div class=" d-flex flex-column">
                <div class="d-flx flex-column">
                  <div class="d-flex justify-content-between align-items-center ">
                    <label for="register-login" class="d-flex flex-grow-1 w-50 align-items-center ">Логин или email </label>
                    <input id="register-login" v-model="loginUser.login" :class="{'border-danger':loginUserErrors.login!=''}" type="text" class="form-control input-group d-flex flex-grow-1 w-50" placeholder="ivanov_ab">
                  </div>
                  <span class="text-danger small w-100" v-if="loginUserErrors.login!=''">{{loginUserErrors.login}}</span>
                </div>
                <div class="d-flx flex-column mt-3">
                  <div class="d-flex justify-content-between align-items-center ">
                    <label for="register-pwd" class="d-flex flex-grow-1 w-50 align-items-center ">Пароль </label>
                    <input id="register-pwd" v-model="loginUser.pwd" :class="{'border-danger':loginUserErrors.pwd!=''}" @keypress.enter="loginAction" type="password" class="form-control input-group d-flex flex-grow-1 w-50">
                  </div>
                  <span class="text-danger small w-100" v-if="loginUserErrors.pwd!=''">{{loginUserErrors.pwd}}</span>
                </div>
                
                <div class="d-flex justify-content-end align-items-center mt-4 text-muted2 small" v-if="allowregister">
                  Нет учетной записи? 
                  <button @click="openRegisterWindow"  class="btn btn-link link-primary ms-3 btn-sm">Зарегистрироваться</button>
                </div>
                <div v-else  class="d-flex justify-content-end align-items-center mt-4 text-muted2 small">
                  Регистрация новых пользователй приостановлена
                </div> 
                <span class="text-danger small w-100" v-if="loginUserErrors.error!=''">{{loginUserErrors.error}}</span>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end">
              <button class="btn btn-primary px-5"
                @click="loginAction"
                :disabled="ajaxBlock">
                Войти
                <font-awesome-icon icon="fa-solid fa-right-to-bracket" class="ms-2"/>
              </button>
            </div>
            
          </div>
      </div>
      <div class="overflow-hidden bg-white position-fixed vh-100 vw-100 expand-block z-1021"></div>
    </div>

    <div class="login-block overflow-auto bg-white position-fixed vh-100 vw-100 expand-block"
      v-if="showRegisterWindow">
      <div class="d-flex justify-content-center align-items-center vh-100 vw-100">
          <div  class="card card-shadow login-box z-1030">
            <div class="card-header d-flex justify-content-between">
              <h3>Регистрация</h3>
              <div @click="closeRegisterWindow" class="cursor-pointer px-3 py-2"><font-awesome-icon icon="fa-solid fa-times" class="text-muted2"/> </div>
            </div>
            <div class="card-body p-4">
              <div class=" d-flex flex-column">
                <div v-if="step==1" class="d-flex flex-column">
                  <div  class="d-flex justify-content-between align-items-center ">
                    <label for="register-surname" class="d-flex flex-grow-1 w-50 align-items-center ">Фамилия </label>
                    <input id="register-surname" v-model="registerUser.surname" :class="{'border-danger':registerUserErrors.surname!=''}" type="text" class="form-control input-group d-flex flex-grow-1 w-50" placeholder="">
                  </div>
                  <span class="text-danger small w-100" v-if="registerUserErrors.surname!=''">{{registerUserErrors.surname}}</span>
                </div>
                <div v-if="step==1"  class="d-flex flex-column">
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <label for="register-name" class="d-flex flex-grow-1 w-50 align-items-center">Имя</label>
                    <input id="register-name" v-model="registerUser.name" :class="{'border-danger':registerUserErrors.name!=''}" type="text" class="form-control input-group d-flex flex-grow-1 w-50" placeholder="">
                  </div>
                  <span class="text-danger small w-100" v-if="registerUserErrors.name!=''">{{registerUserErrors.name}}</span>
                </div>
                <div v-if="step==1"  class="d-flex flex-column">
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <label for="register-thirdname" class="d-flex flex-grow-1 w-50 align-items-center">Отчество</label>
                    <input id="register-thirdname" v-model="registerUser.thirdname" :class="{'border-danger':registerUserErrors.thirdname!=''}" type="text" class="form-control input-group d-flex flex-grow-1 w-50" placeholder="">
                  </div>
                  <span class="text-danger small w-100" v-if="registerUserErrors.thirdname!=''">{{registerUserErrors.thirdname}}</span>
                </div>
                <div v-if="step==1"  class="d-flex flex-column">
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <label for="register-email" class="d-flex flex-grow-1 w-50 align-items-center">Email</label>
                    <input id="register-email" v-model="registerUser.email" :class="{'border-danger':registerUserErrors.email!=''}" type="text" class="form-control input-group d-flex flex-grow-1 w-50" placeholder="">
                  </div>
                  <span class="text-danger small w-100" v-if="registerUserErrors.email!=''">{{registerUserErrors.email}}</span>
                </div>
                <div v-if="step==2"  class="d-flex flex-column">
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <label for="register-phone" class="d-flex flex-grow-1 w-50 align-items-center">Телефон</label>
                    <input id="register-phone" v-model="registerUser.phone" :class="{'border-danger':registerUserErrors.phone!=''}" type="text" class="form-control input-group d-flex flex-grow-1 w-50" placeholder="">
                  </div>
                  <span class="text-danger small w-100" v-if="registerUserErrors.phone!=''">{{registerUserErrors.phone}}</span>
                </div>
                <div v-if="step==2" class="d-flex flex-column">
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <label for="register-city" class="d-flex flex-grow-1 w-50 align-items-center">Город</label>
                    <input id="register-city" v-model="registerUser.city" :class="{'border-danger':registerUserErrors.city!=''}" type="text" class="form-control input-group d-flex flex-grow-1 w-50" placeholder="">
                  </div>
                  <span class="text-danger small w-100" v-if="registerUserErrors.city!=''">{{registerUserErrors.city}}</span>
                </div>
                <div v-if="step==2&&needSchool" class="d-flex flex-column">
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <label for="register-schoolname" class="d-flex flex-grow-1 w-50 align-items-center">Школа</label>
                    <input id="register-schoolname" v-model="registerUser.schoolname" :class="{'border-danger':registerUserErrors.schoolname!=''}" type="text" class="form-control input-group d-flex flex-grow-1 w-50" placeholder="">
                  </div>
                  <span class="text-danger small w-100" v-if="registerUserErrors.schoolname!=''">{{registerUserErrors.schoolname}}</span>
                </div>
                <div v-if="step==2" class="d-flex flex-column">
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <label for="register-pwd" class="d-flex flex-grow-1 w-50">Пароль</label>
                    <input id="register-pwd" v-model="registerUser.pwd" :class="{'border-danger':registerUserErrors.pwd!=''}" type="password" class="form-control input-group d-flex flex-grow-1 w-50">
                  </div>
                  <span class="text-danger small w-100" v-if="registerUserErrors.pwd!=''">{{registerUserErrors.pwd}}</span>
                </div>
                <div v-if="step==2" class="d-flex flex-column">
                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <label for="register-rpwd" class="d-flex flex-grow-1 w-50">Повтор пароля</label>
                    <input id="register-rpwd" v-model="registerUser.rpwd" :class="{'border-danger':registerUserErrors.rpwd!=''}" @keypress.enter="registerAction" type="password" class="form-control input-group d-flex flex-grow-1 w-50">
                  </div>
                  <span class="text-danger small w-100" v-if="registerUserErrors.rpwd!=''">{{registerUserErrors.rpwd}}</span>
                </div>
                <div class="d-flex justify-content-end align-items-center mt-4 text-muted2 small">
                  Есть учетная запись?
                  <button @click="openLoginWindow" class="btn btn-link link-primary ms-3 btn-sm">Войти</button>
                </div>
                <span class="text-danger small w-100" v-if="registerUserErrors.responseerror!=''">{{registerUserErrors.responseerror}}</span>
              </div>
            </div>
            <div class="card-footer d-flex " :class="{'justify-content-between':step > 1, 'justify-content-end':step<totalSteps}">
              
              <button v-if="step > 1" class="btn btn-secpndary bg-gray" @click="prevStep">Назад</button>
              <button v-if="step < totalSteps" class="btn btn-primary" @click="nextStep">Далее</button>
              <button v-else class="btn btn-primary px-5" @click="registerAction"
                :disabled="ajaxBlock">
                Зарегистрироваться
                <font-awesome-icon icon="fa-solid fa-right-to-bracket" class="ms-2"/>
              </button>
            </div>
            
          </div>
      </div>
      <div class="overflow-hidden bg-white position-fixed vh-100 vw-100 expand-block z-1021"></div>
    </div>


    <div class="login-block overflow-auto bg-white position-fixed vh-100 vw-100 expand-block"
      v-if="showAppointmentWindow">
      <div class="d-flex justify-content-center align-items-center vh-100 vw-100">
          <div  class="card card-shadow login-box z-1030">
            <div class="card-header d-flex justify-content-between pb-0">
              <h3>Мини-опрос 🤔💭</h3>
              <div @click="closeAppointmentWindow" class="cursor-pointer px-3 py-2"><font-awesome-icon icon="fa-solid fa-times" class="text-muted2"/> </div>
            </div>
            <div class="card-body ps-4">
              <h5 class="bold">В какой ВУЗ вы планируете поступать?</h5>
              <div class=" d-flex flex-column">
                <div class="d-flex flex-column mb-2">
                  <div  class="d-flex justify-content-between align-items-center ">
                    <input id="appointment-vka" value="vka" v-model="userAppointment.vuz" type="radio" class="d-flex " placeholder="">
                    <label for="appointment-vka" class="d-flex ms-3 flex-grow-1 w-50 align-items-center ">🏛✨ Планирую поступать в Военно-космическую академию имени А.Ф.Можайского</label>
                  </div>
                </div>
                <div class="d-flex flex-column  mb-2">
                  <div  class="d-flex justify-content-between align-items-center ">
                    <input id="appointment-mo" value="mo" v-model="userAppointment.vuz" type="radio" class="d-flex " placeholder="">
                    <label for="appointment-mo" class="d-flex ms-3 flex-grow-1 w-50 align-items-center ">🪖 Буду поступать в другой ВУЗ МО РФ</label>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <div  class="d-flex justify-content-between align-items-center ">
                    <input id="appointment-grazd" value="grazd" v-model="userAppointment.vuz" type="radio" class="d-flex " placeholder="">
                    <label for="appointment-grazd" class="d-flex ms-3 flex-grow-1 w-50 align-items-center ">🏫 Буду поступать в гражданский ВУЗ</label>
                  </div>
                </div>
                <div class="d-flex flex-column mt-4" v-if="userAppointment.vuz=='mo'||userAppointment.vuz=='grazd'">
                  <div  class="d-flex justify-content-between align-items-center ">
                    <label for="appointment-text" class="d-flex flex-grow-1 w-50">В какой именно ВУЗ? ✍🏻</label>
                    <input id="appointment-text" v-model="userAppointment.vuz_text" type="text" class="form-control input-group d-flex flex-grow-1 w-50">
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end ">              
              <button class="btn btn-primary px-5" @click="submitUserAppointmentData"
                :disabled="ajaxBlock">
                Ответить
                <font-awesome-icon icon="fa-solid fa-check" class="ms-2"/>
              </button>
            </div>
            
          </div>
      </div>
      <div class="overflow-hidden bg-white position-fixed vh-100 vw-100 expand-block z-1021"></div>
    </div>

  </div>
</template>

<script>
import { mapGetters,mapMutations } from 'vuex'
export default {
  name: "LoginBlock",
  props: {
    title: String,
  },
  components: {
  },
  data(){
    return {
      step: 1,
      totalSteps: 2,
      ajaxBlock:false,
      // показываем окно логина
      showLoginWindow:false,
      // показываем окно регистрации
      showRegisterWindow:false,
      // показываем окно выбора ВУЗа
      showAppointmentWindow:false,
      // needSchool: true,
      needSchool: false,
      // показать окно сброса пароля
      // showResetPasswordWindow:false,
      // регистрация пользователя
      registerUser:{
        name:'',
        email:'',
        surname:'',
        thirdname:'',
        schoolname:'',
        course:'',
        phone:'',
        city:'',
        pwd:'',
        rpwd:''
      },
      userAppointment:{
        vuz:'',
        vuz_text:'',
      },
      appointment_check:'',
      appointment_text:'',
      // ошибки в полях при регистрации
      registerUserErrors:{
        name:'',
        email:'',
        surname:'',
        thirdname:'',
        schoolname:'',
        phone:'',
        course:'',
        city:'',
        pwd:'',
        rpwd:'',
        responseerror:'',
      },
      // логин пользователя
      loginUser:{
        login:'',
        pwd:''
      },
      // ошибки в полях при регистрации
      loginUserErrors:{
        login:'',
        pwd:'',
        error:'',
      },
    }
  },
  computed:{
    ...mapGetters([
      'isLoggedIn',      
      'isAdmin',
      'hostDebug',
      'authHeader',
      'allowregister',
      'allowresults',
      'allowretest',
      'allowtest',
      'hiddentest',
      'olympfinished',
    ])
  },
  mounted() {
    // сохраняем заголовок авторизации
    if ( this.$cookies.isKey('quiztwt') && this.$cookies.isKey('quiztwt_type') ){
      this.saveHeader( this.$cookies.get('quiztwt_type'), this.$cookies.get('quiztwt') );
    }
    // проверяем залогиненость
    this.checkAuth().then(()=>{
      // this.loadRetest();
      // this.loadAllowregister();
      // this.loadAllowresults();
      // this.loadAllowtest();
      // this.loadHiddentest();
      // this.loadOlympfinished();
      this.loadSettings();
    })
    
  },
  methods:{
    ...mapMutations([
      'setLoggedIn',
      'setAdmin',
      'setUserInfo',
      'setAuthHeader',
      'setAllowRegister',
      'setAllowResults',
      'setAllowRetest',
      'setAllowTest',
      'setHiddenTest',
      'setSettings',
      'setOlympfinished',
    ]),
    nextStep(){
      this.step+=1;
    },
    prevStep(){
      this.step-=1;
    },
    /**
     * Загружаем статус ретестов
     */
     async loadRetest(){
      this.ajaxBlock=true;
      const response = await fetch(this.hostDebug+"/api/retest", {
              headers: { "Content-Type": "application/json",
                          "Accept" : "application/json" },
              method: "GET"
            });
      this.ajaxBlock=false;
      response.json().then( (data)=>{
        if ( data.result && data.retest!==undefined ){
          this.setAllowRetest(data.retest=='True')
        }
      } ) 
    },
    async loadAllowregister(){
      this.ajaxBlock=true;
      const response = await fetch(this.hostDebug+"/api/allowregister", {
              headers: { "Content-Type": "application/json",
                          "Accept" : "application/json" },
              method: "GET"
            });
      this.ajaxBlock=false;
      response.json().then( (data)=>{
        if ( data.result && data.allowregister!==undefined ){
          this.setAllowRegister(data.allowregister=='True')
        }
      } ) 
    },
    async loadAllowresults(){
      this.ajaxBlock=true;
      const response = await fetch(this.hostDebug+"/api/allowresults", {
              headers: { "Content-Type": "application/json",
                          "Accept" : "application/json" },
              method: "GET"
            });
      this.ajaxBlock=false;
      response.json().then( (data)=>{
        if ( data.result && data.allowresults!==undefined ){
          this.setAllowResults(data.allowresults=='True')
        }
      } ) 
    },
    async loadAllowtest(){
      this.ajaxBlock=true;
      const response = await fetch(this.hostDebug+"/api/allowtest", {
              headers: { "Content-Type": "application/json",
                          "Accept" : "application/json" },
              method: "GET"
            });
      this.ajaxBlock=false;
      response.json().then( (data)=>{
        if ( data.result && data.allowtest!==undefined ){
          this.setAllowTest(data.allowtest=='True')
        }
      } ) 
    },
    async loadHiddentest(){
      this.ajaxBlock=true;
      const response = await fetch(this.hostDebug+"/api/hiddentest", {
              headers: { "Content-Type": "application/json",
                          "Accept" : "application/json" },
              method: "GET"
            });
      this.ajaxBlock=false;
      response.json().then( (data)=>{
        if ( data.result && data.hiddentest!==undefined ){
          this.setHiddenTest(data.hiddentest=='True')
        }
      } ) 
    },
    async loadOlympfinished(){
      this.ajaxBlock=true;
      const response = await fetch(this.hostDebug+"/api/olympf", {
              headers: { "Content-Type": "application/json",
                          "Accept" : "application/json" },
              method: "GET"
            });
      this.ajaxBlock=false;
      response.json().then( (data)=>{
        if ( data.result && data.olympfinished!==undefined ){
          this.setOlympfinished(data.olympfinished=='True')
        }
      } ) 
    },
    async loadSettings(){
      this.ajaxBlock=true;
      const response = await fetch(this.hostDebug+"/api/settings", {
              headers: { "Content-Type": "application/json",
                          "Accept" : "application/json" },
              method: "GET"
            });
      this.ajaxBlock=false;
      response.json().then( (data)=>{
        if (data) {
          this.setSettings(data)
        }
      } ) 
    },

    /** 
     * Пытаемся закодировать объект
     */
    urlencodeObject2String(obj){
      let resultarr_ = [];
      for (let i = 0; i < Object.keys(obj).length; i++) {
        const key_ = Object.keys(obj)[i];
        resultarr_.push( encodeURIComponent(key_)+'='+encodeURIComponent(obj[key_]) )
      }
      return resultarr_.join('&');
    },

    /**
     * Авторизация пользователя
     */
    loginAction(){
      // проверка на ошибки
      if ( this.checkLoginErrors() ){
        this.ajaxBlock = true;
        fetch( this.hostDebug+"/api/token", {
          headers: { "Content-Type": "application/x-www-form-urlencoded",
                          "accept" : "application/json" },
          body: this.urlencodeObject2String({username:this.loginUser.login, password:this.loginUser.pwd}), //this.urlencodeObject2String(this.registerUser),
          method: "POST"
        })
        .then( response => {
          this.ajaxBlock = false;
          return response.json()
        } ).then( body=>{
          if ( body ){
            if ( body.access_token  ){
              // console.log(body);
              this.saveHeader(body.token_type,body.access_token);
              this.$cookies.set('quiztwt',body.access_token);
              this.$cookies.set('quiztwt_type',body.token_type);

              this.checkAuth();
              this.closeLoginWindow();
            }else{
              this.registerUserErrors.responseerror = body.error;
              this.$swal( { 
                title: 'Ошибка входа',
                icon: 'error',
                text: body.detail ?? body.error,
              } );
              return;
            }
            
          }
        } ).catch( e=>{
          this.ajaxBlock = false;
          this.$swal( { 
                title: 'Ошибка входа',
                icon: 'error',
                text: e,
              } );
        } ) ;
      }
    },

    /**
     * Регистрируем пользователя
     */
    registerAction(){
      // проверка на ошибки
      if ( this.checkRegisterErrors() ){
        this.ajaxBlock = true;
        fetch( this.hostDebug+"/api/register", {
          headers: { "Content-Type": "application/json",
                          "accept" : "application/json" },
          body: JSON.stringify(this.registerUser), //this.urlencodeObject2String(this.registerUser),
          method: "POST"
        })
        .then( response => {
          this.ajaxBlock = false;
          let body = response.json();
          if ( response.status>=400 ){
            this.$swal( { 
              title: 'Error',
              icon: 'error',
              text: body.detail,
            } );
            return false;
          }
          if ( response.status==200 ){
            return body
          }
          return false;
        } ).then( body=>{
          if ( body ){
            if ( body.id>0  ){
              this.$swal( { 
                title: 'Успешная регистрация',
                icon: 'success',
                html: "<div class='d-flex flex-column'> <div>Вы успешно зарегистрировались!</div> <div>Ваш логин для входа:</div> <div class='login-success p-3 mt-2'>"+body.login+"</div>",
              } );
              this.loginUser.login = body.login;
              this.loginUser.pwd = '';
              this.loginUserErrors.login = '';
              this.loginUserErrors.pwd = '';
              this.loginUserErrors.error = '';
              this.registerUser.name = '';
              this.registerUser.email = '';
              this.registerUser.phone = '';
              this.registerUser.city = '';
              this.registerUser.schoolname = '';
              this.registerUser.course = '';
              this.registerUser.surname = '';
              this.registerUser.thirdname = '';
              this.registerUser.pwd = '';
              this.registerUser.rpwd = '';
              this.step=1;
              this.closeRegisterWindow();
              this.openLoginWindow();
            }else{
              this.registerUserErrors.responseerror = body.error;
              this.$swal( { 
                title: 'Ошибка регистрации',
                icon: 'error',
                text: body.error,
              } );
              return;
            }
            // this.saveHeader(body.token_type,body.access_token);
            // this.$cookies.set('quiztwt',body.access_token);
            // this.$cookies.set('quiztwt_type',body.token_type);
          }
        } ) ;
      }
    },


    /**
     * Проверяем на введенные ошибки
     */
    checkLoginErrors(){
      let result = true;
      this.loginUserErrors.login = '';
      this.loginUserErrors.pwd = '';

      if ( this.loginUser.login=='' ){
        this.loginUserErrors.login='Заполните логин пользователя';
        result = false;
      }
      if ( this.loginUser.pwd=='' ){
        this.loginUserErrors.pwd='Заполните поле пароля';
        result = false;
      }
      return result;
    },

    /**
     * Проверяем на введенные ошибки
     */
    checkRegisterErrors(){
      let result = true;
      this.registerUserErrors.name = '';
      this.registerUserErrors.surname = '';
      this.registerUserErrors.thirdname = '';
      this.registerUserErrors.phone = '';
      this.registerUserErrors.email = '';
      this.registerUserErrors.schoolname = '';
      this.registerUserErrors.course = '';
      this.registerUserErrors.city = '';
      this.registerUserErrors.pwd = '';
      this.registerUserErrors.rpwd = '';

      if ( this.registerUser.name=='' ){
        this.registerUserErrors.name='Заполните имя пользователя';
        result = false;
      }
      if ( this.registerUser.email=='' ){
        this.registerUserErrors.email='Заполните почту';
        result = false;
      }
      if ( this.registerUser.surname=='' ){
        this.registerUserErrors.surname='Заполните фамилию пользователя';
        result = false;
      }
      if ( this.registerUser.thirdname=='' ){
        this.registerUserErrors.thirdname='Заполните отчество пользователя';
        result = false;
      }
      if ( this.registerUser.pwd=='' ){
        this.registerUserErrors.pwd='Заполните поле пароля';
        result = false;
      }
      if ( this.registerUser.phone=='' ){
        this.registerUserErrors.phone='Введите номер телефона';
        result = false;
      }
      if ( this.registerUser.schoolname=='' && this.needSchool ){
        this.registerUserErrors.schoolname='Введите номер школы';
        result = false;
      }
      if ( this.registerUser.city=='' ){
        this.registerUserErrors.city='Введите город в котором вы проживаете';
        result = false;
      }
      if ( this.registerUser.rpwd=='' ){
        this.registerUserErrors.rpwd='Заполните поле повторного пароля';
        result = false;
      }
      if ( this.registerUser.rpwd!=this.registerUser.pwd ){
        this.registerUserErrors.rpwd='Пароли не совпадают';
        result = false;
      }
      return result;
    },

    /**
     * Закрываем окно логина
     */ 
    closeLoginWindow(){
      this.showLoginWindow = false;
    },

    closeAppointmentWindow(){
      this.showAppointmentWindow = false;
    },

    /**
     * Открываем окно логина
     */
    openLoginWindow(){
      // если уже зарегистрированы - то ничего не показываем
      if (this.isLoggedIn){
        return;
      }
      this.closeAllWindows();
      this.showLoginWindow = true;
    },

    /**
     * Закрываем окно логина
     */ 
    closeRegisterWindow(){
      this.showRegisterWindow = false;
    },

    /**
     * Открываем окно логина
     */
    openRegisterWindow(){
      // если уже зарегистрированы - то ничего не показываем
      if (this.isLoggedIn){
        return;
      }
      this.closeAllWindows();
      this.showRegisterWindow = true;
    },

    /**
     * Закрываем все окна
     */
    closeAllWindows(){
      this.showLoginWindow = false;
      this.showRegisterWindow = false;
      this.showAppointmentWindow = false;
    },

    /**
     * Сохраняем заголовок
     */
    saveHeader(token_type, token){
      this.setAuthHeader( {
        "Authorization":token_type+" "+token,
        "Sec-Fetch-Mode":	"cors",
        "Sec-Fetch-Site": "same-origin"
      });
    },


    /**
     * Проверяем авторизован ли пользователь
     */
    async checkAuth(info=true){
      const response = await fetch(this.hostDebug+"/api/isauth", {
                headers: { "Content-Type": "application/x-www-form-urlencoded",
                           "accept" : "application/json",
                           ...this.authHeader },
                method: "GET"
              });
      response.json().then( (data)=>{
        if ( response.status>=400 && info ){
          // this.$swal( { 
          //   title: 'Error',
          //   icon: 'error',
          //   text: data.detail,
          // } );
          // Redirect to the main page if route requires authentication
          
          if (this.$route.meta.requiresAuth) {
              this.$router.push('/');
          }
          return false;
        }
        if ( data.auth ){
          this.setLoggedIn(true);
          this.loadUserInfo();
          if ( data.admin ){
            this.setAdmin(true);
          }
          if (this.$route.meta.requiresAdmin && !data.admin) {
              this.$router.push('/');
          }
        }else {
          // Redirect to the main page if route requires authentication
          if (this.$route.meta.requiresAuth || this.$route.meta.requiresAdmin) {
              this.$router.push('/');
              return false;
          }
        }
      } )
    },

    async loadUserInfo(){
      const response = await fetch(this.hostDebug+"/api/user_appointment", {
                headers: { "Content-Type": "application/x-www-form-urlencoded",
                           "accept" : "application/json",
                           ...this.authHeader },
                method: "GET"
              });
      response.json().then( (data)=>{
        if ( data.vuz == null ){
          this.showAppointmentWindow = true;
        }
        this.setUserInfo(data);
      } )
    },

    /**
     * Сохраняем данные голосования за ВУЗ
     */
    submitUserAppointmentData(){
      this.ajaxBlock = true;
      fetch( this.hostDebug+"/api/user_appointment", {
        headers: { "Content-Type": "application/json",
                      "accept" : "application/json",
                      ...this.authHeader },
        body: JSON.stringify(this.userAppointment),
        method: "POST"
      }).then( response => {
        this.ajaxBlock = false;
        let body = response.json();
        if ( response.status>=400 ){
          this.$swal( { 
            title: 'Error',
            icon: 'error',
            text: body.detail,
          } );
          return false;
        }
        if ( response.status==200 ){
          this.$swal( {
            iconHtml: "<img class='w-100' src='https://images.emojiterra.com/google/noto-emoji/animated-emoji/1f389.gif'>",
            html: "<div class='d-flex flex-column'> <div>Благодарим за участие в опросе!</div> </div>",
            customClass: {
              icon: 'no-border w-100 wh-10em'
            },
            confirmButtonText: 'Пожалуйста!',
          })

          this.showAppointmentWindow = false;
          return body
        }
        return false;
      })
    },

    /**
     * Разлогиневаемся
     */ 
    logout(){
      this.$cookies.set('quiztwt','');
      this.$cookies.set('quiztwt_type','');
      this.setAuthHeader(false),
      this.setLoggedIn(false);
      this.setAdmin(false);
      this.$router.push('/');
    },
    //$emit('loginEvent')
  },
  
};
</script>

