import {createApp} from 'vue'
import VueCookies from 'vue3-cookies'
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style.scss'
import 'sweetalert2/dist/sweetalert2.min.css';


import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)

import 'bootstrap/js/src/base-component'
import 'bootstrap/js/src/dropdown'

const app = createApp(App);

app.use(VueCookies,{ expire: '7d'})
.component('font-awesome-icon', FontAwesomeIcon)
.use(VueSweetalert2)
.use(router)
.use(store)
.mount('#app')
