<template>
  <router-view />
</template>

<script>
import { mapMutations,mapGetters } from 'vuex'

export default{
    data(){
        return{
            provider: null,
        }
    },
    watch: {
      
    },
    methods: {
        

      ...mapMutations([
        // 'setUserAccount',
      ]),
    },
    computed:{
      ...mapGetters([
        // 'activepool',
      ])
    },
    mounted(){

      // this.$bus.$on('quiz::refund', ()=>this.refund() );     
      
    },
    created(){
      
    }
}
</script>