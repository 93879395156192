<template>
  <div class="home">
    <LoginBar @login-event="login" @logout-event="logout" ref="loginbar"></LoginBar>
    
    <header class="hero-section text-center">
      <div class="container">
          <h1 class="mb-4">🎓 Всероссийская онлайн-олимпиада</h1>
          <h2>Военно-космическая академия имени А.Ф. Можайского</h2>
          <h3>Факультет 5 - сбора и обработки информации</h3>
          <h4>Кафедра 52 - технологий и средств геофизического обеспечения</h4>
          
          <div class="mt-4">
              <button v-if="(!allowtest || hiddentest) && !olympfinished" class="cta-button"  @click="registerwindow">Регистрация открыта до 14.00 19 декабря</button>
              <button v-if="allowtest && !isLoggedIn && !hiddentest && !olympfinished" class="cta-button"  @click="loginwindow">🔔 Олимпиада началась! Для участия необходимо войти в систему</button>

              <div class="row" v-if="allowtest&&isLoggedIn&&!hiddentest">
                <h2>Доступные тесты</h2>
                  <div  
                    v-for="(item,index) in quizList"
                    :key="index"
                    class="card card-shadow me-3 mt-3 col"
                    href="/quiz/start/{{item.id}}" >
                    <div class="card-header">
                      <h4>{{item.title}}</h4>
                    </div>
                    <div class="card-footer">
                      <button class="btn btn-primary" @click="startQuiz(item.id)">Пройти тест</button>
                    </div>
                  </div>
                
              </div>

          </div>
      </div>
  </header>

<main class="container py-5">

    <div v-if="olympfinished" class="alert alert-success mt-5 p-4 rounded-lg text-center d-flex flex-column align-items-center">
      <img  style="width:200px" src="https://images.emojiterra.com/google/noto-emoji/animated-emoji/1f913.gif">
      <h3>Олимпиада завершена!</h3>
      <p v-if="isLoggedIn">скачать диплом участника можно на странице <a href="/dashboard" class="text-primary">пользователя</a> </p>
      <p v-if="!isLoggedIn">для того, чтобы скачать диплом необходимо авторизоваться</p>
    </div>

    <div v-if="olympfinished" class="alert alert-danger mt-5 p-4 rounded-lg text-center d-flex flex-column align-items-center">
      <h1>🏆</h1>
      <h3><strong>Первое</strong> место с результатом 250 баллов заняли:</h3>
      <div class="d-flex flex-row flex-wrap">
        <div class="p-1 btn btn-danger m-1">Анисимова А.А.</div>
        <div class="p-1 btn btn-danger m-1">Быстрова В.А.</div>
        <div class="p-1 btn btn-danger m-1">Воробьева Я.И.</div>
        <div class="p-1 btn btn-danger m-1">Грунин Д.В.</div>
        <div class="p-1 btn btn-danger m-1">Даштар-оол T.А.</div>
        <div class="p-1 btn btn-danger m-1">Джатдоева А.Ш.</div>
        <div class="p-1 btn btn-danger m-1">Дилдебаев З.М.</div>
        <div class="p-1 btn btn-danger m-1">Забабурина Я.А.</div>
        <div class="p-1 btn btn-danger m-1">Кзыков К.В.</div>
        <div class="p-1 btn btn-danger m-1">Кольцов А.Е.</div>
        <div class="p-1 btn btn-danger m-1">Косенко Д.С.</div>
        <div class="p-1 btn btn-danger m-1">Кудрина А.С.</div>
        <div class="p-1 btn btn-danger m-1">Кузнецова Я.А.</div>
        <div class="p-1 btn btn-danger m-1">Малышев К.М.</div>
        <div class="p-1 btn btn-danger m-1">Немигалова К.Д.</div>
        <div class="p-1 btn btn-danger m-1">Новикова К.А.</div>
        <div class="p-1 btn btn-danger m-1">Нягина К.А.</div>
        <div class="p-1 btn btn-danger m-1">Орлова П.С.</div>
        <div class="p-1 btn btn-danger m-1">Осипов К.С.</div>
        <div class="p-1 btn btn-danger m-1">Пищерев Р.А.</div>
        <div class="p-1 btn btn-danger m-1">Русских Н.В.</div>
        <div class="p-1 btn btn-danger m-1">Садырова  М.Б. </div>
        <div class="p-1 btn btn-danger m-1">Серёгина А.М.</div>
        <div class="p-1 btn btn-danger m-1">Шлюбченко Д.А.</div>
      </div>
    </div>

    <div v-if="olympfinished" class="alert alert-info mt-5 p-4 rounded-lg text-center d-flex flex-column align-items-center">
      <h3> <strong>Второе</strong> место с результатом 245 баллов заняли:</h3>
      <div class="d-flex flex-row flex-wrap">
        <div class="p-1 btn btn-info m-1">Агеева А.С.</div>
        <div class="p-1 btn btn-info m-1">Адамсон К.В.</div>
        <div class="p-1 btn btn-info m-1">Алексеев В.В.</div>
        <div class="p-1 btn btn-info m-1">Андюкова К.Л.</div>
        <div class="p-1 btn btn-info m-1">Афанасьев П.Е.</div>
        <div class="p-1 btn btn-info m-1">Белоногова Н.В.</div>
        <div class="p-1 btn btn-info m-1">Белоусов В.Н.</div>
        <div class="p-1 btn btn-info m-1">Белоусова К.А.</div>
        <div class="p-1 btn btn-info m-1">Васильев Р.Е.</div>
        <div class="p-1 btn btn-info m-1">Витютнева А.Ю.</div>
        <div class="p-1 btn btn-info m-1">Вяцкова Д.В.</div>
        <div class="p-1 btn btn-info m-1">Гадальшина В.Р.</div>
        <div class="p-1 btn btn-info m-1">Гонтарь И.А.</div>
        <div class="p-1 btn btn-info m-1">Гурвич А.А.</div>
        <div class="p-1 btn btn-info m-1">Дербунов С.Э.</div>
        <div class="p-1 btn btn-info m-1">Дюдин И.С.</div>
        <div class="p-1 btn btn-info m-1">Егорова Т.Р.</div>
        <div class="p-1 btn btn-info m-1">Жадько К.Е.</div>
        <div class="p-1 btn btn-info m-1">Иванов А.Н.</div>
        <div class="p-1 btn btn-info m-1">Капаев С.Д.</div>
        <div class="p-1 btn btn-info m-1">Карпова Д.А.</div>
        <div class="p-1 btn btn-info m-1">Карякина А.К.</div>
        <div class="p-1 btn btn-info m-1">Катькало А.А.</div>
        <div class="p-1 btn btn-info m-1">Корнева А.С.</div>
        <div class="p-1 btn btn-info m-1">Кочетков П.Н.</div>
        <div class="p-1 btn btn-info m-1">Кузнецов В.А.</div>
        <div class="p-1 btn btn-info m-1">Кузьмин М.Ю.</div>
        <div class="p-1 btn btn-info m-1">Лунева С.В.</div>
        <div class="p-1 btn btn-info m-1">Майдов И.А.</div>
        <div class="p-1 btn btn-info m-1">Макашова К.В.</div>
        <div class="p-1 btn btn-info m-1">Малышева А.А.</div>
        <div class="p-1 btn btn-info m-1">Минакова М.С.</div>
        <div class="p-1 btn btn-info m-1">Оморалиев Б.Ш.</div>
        <div class="p-1 btn btn-info m-1">Остапенко М.В.</div>
        <div class="p-1 btn btn-info m-1">Першина Т.С.</div>
        <div class="p-1 btn btn-info m-1">Пономарева К.С.</div>
        <div class="p-1 btn btn-info m-1">Саая Ч.К.</div>
        <div class="p-1 btn btn-info m-1">Садаков Д.А.</div>
        <div class="p-1 btn btn-info m-1">Спесивцева М.А.</div>
        <div class="p-1 btn btn-info m-1">Темчук М.И.</div>
        <div class="p-1 btn btn-info m-1">Фирсова Т.А.</div>
        <div class="p-1 btn btn-info m-1">Хомушку Т.С.</div>
        <div class="p-1 btn btn-info m-1">Чернова С.Н.</div>
        <div class="p-1 btn btn-info m-1">Ширяев К.Р.</div>
        <div class="p-1 btn btn-info m-1">Ягодов А.А.</div>
      </div>
    </div>

    <div v-if="olympfinished" class="alert alert-warning mt-5 p-4 rounded-lg text-center d-flex flex-column align-items-center">
      <h3> <strong>Третье</strong> место с результатом 240 баллов заняли:</h3>
      <div class="d-flex flex-row flex-wrap">
        <div class="p-1 btn btn-warning m-1">Абрамова Д.В.</div>
        <div class="p-1 btn btn-warning m-1">Андриянова А.О.</div>
        <div class="p-1 btn btn-warning m-1">Байназарова С.А.</div>
        <div class="p-1 btn btn-warning m-1">Беккер К.З.</div>
        <div class="p-1 btn btn-warning m-1">Бельков А.И.</div>
        <div class="p-1 btn btn-warning m-1">Белякова А.А.</div>
        <div class="p-1 btn btn-warning m-1">Бойкова А.Н.</div>
        <div class="p-1 btn btn-warning m-1">Бондарев Я.М.</div>
        <div class="p-1 btn btn-warning m-1">Брискин  М.О.</div>
        <div class="p-1 btn btn-warning m-1">Винник И.В.</div>
        <div class="p-1 btn btn-warning m-1">Виноградов Г.А.</div>
        <div class="p-1 btn btn-warning m-1">Голованова У.С.</div>
        <div class="p-1 btn btn-warning m-1">Головачев Е.А.</div>
        <div class="p-1 btn btn-warning m-1">Гордеева А.В.</div>
        <div class="p-1 btn btn-warning m-1">Григорьева А.А.</div>
        <div class="p-1 btn btn-warning m-1">Гущин Т.В.</div>
        <div class="p-1 btn btn-warning m-1">Джалиева А.Р.</div>
        <div class="p-1 btn btn-warning m-1">Долматов Д.М.</div>
        <div class="p-1 btn btn-warning m-1">Дроздов Д.А.</div>
        <div class="p-1 btn btn-warning m-1">Дудиев Г.С.</div>
        <div class="p-1 btn btn-warning m-1">Елина В.Е.</div>
        <div class="p-1 btn btn-warning m-1">Жакпаров В.А.</div>
        <div class="p-1 btn btn-warning m-1">Жидяева В.А.</div>
        <div class="p-1 btn btn-warning m-1">Игнатова С.А.</div>
        <div class="p-1 btn btn-warning m-1">Ильин Б.С.</div>
        <div class="p-1 btn btn-warning m-1">Илюшкина С.Ю.</div>
        <div class="p-1 btn btn-warning m-1">Кадеев К.И.</div>
        <div class="p-1 btn btn-warning m-1">Карташов Н.С.</div>
        <div class="p-1 btn btn-warning m-1">Касьянова Ю.С.</div>
        <div class="p-1 btn btn-warning m-1">Кирина  В.Н.</div>
        <div class="p-1 btn btn-warning m-1">Кислицын М.А.</div>
        <div class="p-1 btn btn-warning m-1">Колесникова М.Д.</div>
        <div class="p-1 btn btn-warning m-1">Коровин Н.С.</div>
        <div class="p-1 btn btn-warning m-1">Косвинцев В.А.</div>
        <div class="p-1 btn btn-warning m-1">Криушкина М.А.</div>
        <div class="p-1 btn btn-warning m-1">Кузнецов Н.С.</div>
        <div class="p-1 btn btn-warning m-1">Кулебаева  А.А.</div>
        <div class="p-1 btn btn-warning m-1">Курбатова К.М.</div>
        <div class="p-1 btn btn-warning m-1">Лаврин П.Д.</div>
        <div class="p-1 btn btn-warning m-1">Леонова Д.А.</div>
        <div class="p-1 btn btn-warning m-1">Леонтьев Н.А.</div>
        <div class="p-1 btn btn-warning m-1">Лихобабин Д.Э.</div>
        <div class="p-1 btn btn-warning m-1">Лукин А.А.</div>
        <div class="p-1 btn btn-warning m-1">Макеев М.П.</div>
        <div class="p-1 btn btn-warning m-1">Макеева П.П.</div>
        <div class="p-1 btn btn-warning m-1">Маломужева Е.И.</div>
        <div class="p-1 btn btn-warning m-1">Маркова К.Е.</div>
        <div class="p-1 btn btn-warning m-1">Мартынова А.Н.</div>
        <div class="p-1 btn btn-warning m-1">Махроа Д.Е.</div>
        <div class="p-1 btn btn-warning m-1">Миргородская М.А.</div>
        <div class="p-1 btn btn-warning m-1">Москлякова П.Р.</div>
        <div class="p-1 btn btn-warning m-1">Николаев В.В.</div>
        <div class="p-1 btn btn-warning m-1">Носов С.В.</div>
        <div class="p-1 btn btn-warning m-1">Осинцева В.А.</div>
        <div class="p-1 btn btn-warning m-1">Осипова Е.Р.</div>
        <div class="p-1 btn btn-warning m-1">Панкратова П.В.</div>
        <div class="p-1 btn btn-warning m-1">Перчук М.А.</div>
        <div class="p-1 btn btn-warning m-1">Петров А.Д.</div>
        <div class="p-1 btn btn-warning m-1">Пищулина А.В.</div>
        <div class="p-1 btn btn-warning m-1">Побережный А.В.</div>
        <div class="p-1 btn btn-warning m-1">Половецкий А.Д.</div>
        <div class="p-1 btn btn-warning m-1">Поляков С.А.</div>
        <div class="p-1 btn btn-warning m-1">Прохоров А.В.</div>
        <div class="p-1 btn btn-warning m-1">Рапава Л.Э.</div>
        <div class="p-1 btn btn-warning m-1">Решетников М.И.</div>
        <div class="p-1 btn btn-warning m-1">Романова М.Е.</div>
        <div class="p-1 btn btn-warning m-1">Рудакова А.А.</div>
        <div class="p-1 btn btn-warning m-1">Русалина К.А.</div>
        <div class="p-1 btn btn-warning m-1">Рыжикова С.В.</div>
        <div class="p-1 btn btn-warning m-1">Рыжков Т.И.</div>
        <div class="p-1 btn btn-warning m-1">Саргсян И.Д.</div>
        <div class="p-1 btn btn-warning m-1">Семагин Е.О.</div>
        <div class="p-1 btn btn-warning m-1">Сибко Д.А.</div>
        <div class="p-1 btn btn-warning m-1">Сидоркин С.Д.</div>
        <div class="p-1 btn btn-warning m-1">Сидорова С.В.</div>
        <div class="p-1 btn btn-warning m-1">Сорокин А.А.</div>
        <div class="p-1 btn btn-warning m-1">Спиридонов  Л.А.</div>
        <div class="p-1 btn btn-warning m-1">Спицына Т.П.</div>
        <div class="p-1 btn btn-warning m-1">Стасенко А.Д.</div>
        <div class="p-1 btn btn-warning m-1">Стырцев М.С.</div>
        <div class="p-1 btn btn-warning m-1">Суворов В.О.</div>
        <div class="p-1 btn btn-warning m-1">Тасырова А.К.</div>
        <div class="p-1 btn btn-warning m-1">Теплых Д.Д.</div>
        <div class="p-1 btn btn-warning m-1">Терехина Е.С.</div>
        <div class="p-1 btn btn-warning m-1">Терешкова В.А.</div>
        <div class="p-1 btn btn-warning m-1">Трофимова Д.П.</div>
        <div class="p-1 btn btn-warning m-1">Трушин Ю.А.</div>
        <div class="p-1 btn btn-warning m-1">Тутаев А.В.</div>
        <div class="p-1 btn btn-warning m-1">Тутаев В.В.</div>
        <div class="p-1 btn btn-warning m-1">Урягаева Д.А.</div>
        <div class="p-1 btn btn-warning m-1">Ухлова В.А.</div>
        <div class="p-1 btn btn-warning m-1">Федоров А.И.</div>
        <div class="p-1 btn btn-warning m-1">Филиппов Т.П.</div>
        <div class="p-1 btn btn-warning m-1">Фомин Я.В.</div>
        <div class="p-1 btn btn-warning m-1">Халиков А.А.</div>
        <div class="p-1 btn btn-warning m-1">Цветков Г.М.</div>
        <div class="p-1 btn btn-warning m-1">Цветков Е.В.</div>
        <div class="p-1 btn btn-warning m-1">Чернова А.А.</div>
        <div class="p-1 btn btn-warning m-1">Чумуркин Е.А.</div>
        <div class="p-1 btn btn-warning m-1">Шекунова В.К.</div>
        <div class="p-1 btn btn-warning m-1">Шемарулина Т.Т.</div>
        <div class="p-1 btn btn-warning m-1">Шигапов В.Р.</div>
        <div class="p-1 btn btn-warning m-1">Эгамбердиева М.А.</div>
        <div class="p-1 btn btn-warning m-1">Юсова К.А.</div>
        <div class="p-1 btn btn-warning m-1">Яковлев Д.Е.</div>
        <div class="p-1 btn btn-warning m-1">Яшкова А.А.</div>
        <div class="p-1 btn btn-warning m-1">Zabavin D.A.</div>
      </div>
    </div>


    <!-- Timeline Section -->
    <section class="timeline-container">
        <h2 class="text-center mb-4">📅 Важные даты</h2>
        <div class="d-flex justify-content-around text-center">
            <div>
                <h4>Регистрация</h4>
                <p>c 10:00 МСК 18 декабря 2024<br/>по 14:00 МСК 19 декабря 2024</p>
            </div>
            <div>
                <h4>Олимпиада</h4>
                <p>19 декабря 2024<br/>14:10 - 24:00 МСК</p>
            </div>
            <div>
                <h4>Результаты</h4>
                <p>26 декабря 2024<br/>12:00 МСК</p>
            </div>
        </div>
    </section>

    <!-- Information Cards -->
    <div class="row">
        <div class="col-md-4">
            <div class="feature-card">
                <h3>📝 Формат олимпиады</h3>
                <p>Тестовые задания включают:</p>
                <ul>
                    <li>Задания с однозначным выбором</li>
                </ul>
            </div>
        </div>
        <div class="col-md-4">
            <div class="feature-card">
                <h3>🎯 Система оценивания</h3>
                <p>Критерии оценки:</p>
                <ul>
                    <li>Правильность ответов</li>
                    <li>Скорость выполнения</li>
                    <li>Полнота решения</li>
                </ul>
            </div>
        </div>
        <div class="col-md-4">
            <div class="feature-card">
                <h3>🏆 Награждение</h3>
                <p>Победители получают:</p>
                <ul>
                    <li>Дипломы победителей</li>
                    <li>Преимущество при поступлении</li>
                </ul>
            </div>
        </div>
    </div>

    
    <!-- Status Messages -->
    <div v-if="(!allowtest || hiddentest) && !olympfinished" class="alert alert-info mt-5 p-4 rounded-lg">
        <h3>⏳ Олимпиада еще не началась</h3>
        <p v-if="allowregister">Сейчас доступна <a href="#" @click="registerwindow" class="text-primary">регистрация</a> участников</p>
    </div>

    <div v-if="allowtest && !isLoggedIn && !hiddentest && !olympfinished" class="alert alert-info mt-5 p-4 rounded-lg">
        <h3>🔔 Олимпиада началась!</h3>
        <p>Для участия необходимо войти в систему</p>
    </div>

    
    <!-- About Academy Section -->
    <section class="mt-5">
        <h2 class="text-center mb-4">🚀 О нашей академии</h2>
        <div class="row">
            <div class="col-md-6">
                <div class="feature-card">
                    <h3>Видео о ВКА</h3>
                    
                    <div class="ratio ratio-16x9 mb-2">
                      <iframe width="720" height="405" src="https://rutube.ru/play/embed/775fdba2fb98692bc877bfee368bf6bc/" frameBorder="0" allow="clipboard-write;" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                    </div>
                    <div class="ratio ratio-16x9 mb-2">
                      <iframe width="560" height="315" src="https://www.youtube.com/embed/LZEXSoTFxGs?si=Ks9ZzmGUo1fqDH2d" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <!--div class="ratio ratio-16x9 ">
                      <iframe width="560" height="315" src="//www.1tv.ru/embed/206254:12" allow="encrypted-media; picture-in-picture" frameborder="0" allowfullscreen></iframe>
                    </!div-->
                    
                </div>
            </div>
            <div class="col-md-6">
                <div class="feature-card">
                    <h3>52 кафедра</h3>
                    <div class="ratio ratio-16x9 mb-2">
                      <iframe width="560" height="315" src="https://www.youtube.com/embed/iF8jCdCicms?si=iCbOOh0jcAeCgBBi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <div class="ratio ratio-16x9 mb-2">
                        <a href="https://vka.mil.ru/video/302841?ysclid=m47bykduo079074228"><img src="/img/video1.jpg" alt="Academy photo 2" class="img-fluid rounded"/></a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Test Section (Conditional Rendering) -->
    <section v-if="allowtest && isLoggedIn" class="mt-5">
        <h2 class="text-center mb-4">📋 Доступные тесты</h2>
        <div class="row">
            <div v-for="(item, index) in quizList" :key="index" class="col-md-4">
                <div class="feature-card">
                    <h4>{{item.title}}</h4>
                    <button class="cta-button w-100" @click="startQuiz(item.id)">
                        Начать тест
                    </button>
                </div>
            </div>
        </div>
    </section>



    <section class="mt-5">
        <h2 class="text-center mb-4">🚀 Абитуриенту</h2>
        <div class="row">
            <div class="col-md-12">
                <div class="feature-card">
                  <img src="/img/page2.png" alt="правила поступления" class="img-fluid rounded"/>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-md-12">
              <div class="feature-card">
                <img src="/img/page.png" alt="правила поступления" class="img-fluid rounded"/>
              </div>
          </div>
      </div>
    </section>

</main>

<footer class="mt-5 py-4 bg-white">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h4>Контакты</h4>
                <p>Email: <a href="mailto:info@meteoquiz.ru">info@meteoquiz.ru</a></p>
                <p>Адрес: г. Санкт-Петербург, ул. Ждановская, д. 13</p>
            </div>
            <div class="col-md-6 text-md-end">
                <p>© 2024 <a href="https://vka.mil.ru/">ВКА им.А.Ф.Можайского</a></p>
                <p>Все права защищены</p>
            </div>
        </div>
    </div>
</footer>
    
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import LoginBar from '../components/LoginBar.vue'

export default {
  name: "HomePage",
  data(){
    return {
      freeSpace: 0,
      quizList:[]
    }
  },
  components: {
    LoginBar
  },
  mounted() {
    this.loadQuiz();
  },
  watch:{
    isLoggedIn(n, old){
      if (old == false && n == true){
        this.loadQuiz();  
      }      
    }
  },
  methods:{
    ...mapMutations([
        // 'setActivePool',
        // 'setPools',
      ]),

    /**
     * Стартуем тест, но сначала проверяем залогинен ли пользователь
     */
    startQuiz(quiz_id){
      if (!this.isLoggedIn){
        this.$swal( { 
            title: 'Доступ запрещен',
            icon: 'error',
            text: "Для прохождения теста необходимо зарегистрироваться и войти в систему.",
          } );
        return;
      }
      if (quiz_id == null || quiz_id == undefined){
        this.$swal( { 
            title: 'Ошибка',
            icon: 'error',
            text: "Тест не найден. Перезагрузите страницу",
          } );
        return;        
      }
      this.$router.push('/quiz/'+quiz_id)
    },

    /**
     * Загружаем данные об операциях
     */
    async loadQuiz(){
      this.ajaxBlock = true;
      this.datatable = [];
      const response = await fetch(this.hostDebug+"/api/quiz", {
                headers: { "Content-Type": "application/json",
                          "Accept" : "application/json",
                          ...this.authHeader },
                method: "GET"
              });
      this.ajaxBlock=false;
      response.json().then( (data)=>{
        if ( data ){
          this.quizList = data;
        }
      } )
    },

    login(){
      console.log("login event");
    },

    logout(){
      console.log("logout event");
    },

    registerwindow(){
      // console.log("register event");
      this.$refs.loginbar.openRegisterWindow();
    },

    loginwindow(){
      this.$refs.loginbar.openLoginWindow();
    }
  },
  computed:{
    ...mapGetters([
      'hostDebug',
      'isLoggedIn',
      'isAdmin',
      'authHeader',
      'allowtest',
      'hiddentest',
      'olympfinished',
      'allowregister'
    ]),
   
  },
};
</script>
